import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
declare const $: any;

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  shopId = 1;

  shopTheming: ShopTheming[] = [];

  navBarCatergories: NavbarCategory[] = [];

  termsAndConditions: string =
    'https://mobydyg-files.s3.amazonaws.com/1/dev/config-files/terms-file.html';
  privacyPolicies: string =
    'https://mobydyg-files.s3.amazonaws.com/1/dev/config-files/policy-file.html';

  constructor(public apiService: ApiService, private router: Router) {}

  async getNavbarCategories() {
    return this.apiService.getThemingService(this.shopId).then((data: any) => {
      this.navBarCatergories = data.categories.map((category: any) => {
        let finalCategory: any = {};
        finalCategory['categoryId'] = category.categoryId;
        finalCategory['name'] = category.categoryName;
        finalCategory['icon'] = category.categoryNonActiveIcon;
        finalCategory['iconActive'] = category.categoryActiveIcon;

        return finalCategory;
      });
    });
  }

  async getThemingService() {
    return this.apiService
      .getThemingService(this.shopId)
      .then((data: any) => {
        console.log(data);
        this.shopTheming = data.themingService.map((theme: any) => {

        this.termsAndConditions = `https://mobydyg-files.s3.amazonaws.com/${theme.shopId}/${environment.stage == 'prod' ? 'prod' : 'dev'}/config-files/terms-file.html`;
        this.privacyPolicies = `https://mobydyg-files.s3.amazonaws.com/${theme.shopId}/${environment.stage == 'prod' ? 'prod' : 'dev'}/config-files/policy-file.html`;

          let finalTheme: any = {};
          finalTheme['shopId'] = theme.shopId;
          finalTheme['shopName'] = theme.shopName;
          finalTheme['currentContract'] = theme.shopCurrentContract;
          finalTheme['country'] = data.country[0].countryName;
          finalTheme['countryLanguage'] = data.country[0].countryLanguage;
          finalTheme['countryCode'] = data.country[0].countryCode;
          finalTheme['countryTimeZone'] = data.country[0].countryTimeZone;
          finalTheme['currencyCode'] = data.currency[0].currencyCode;
          finalTheme['messaging'] = theme.shopMessaging;
          finalTheme['messageImage'] = data.themingService[0].messageImage;
          finalTheme['mail'] = theme.shopMail;
          finalTheme['phone'] = data.shopPoints[0].shopPointPhone;
          finalTheme['version'] = theme.versionBackoffice;
          finalTheme['countryTimeZone'] = data.country[0].countryTimeZone;

          finalTheme['socialMedia'] = data.socialMedia.map(
            (socialMedia: any) => {
              let finalSocialMedia: any = {};
              finalSocialMedia['socialMediaName'] = socialMedia.socialMediaName;
              finalSocialMedia['socialMediaURL'] = socialMedia.socialMediaURL;
              finalSocialMedia['socialMediaIcon'] = socialMedia.socialMediaIcon;
              return finalSocialMedia;
            }
          );
          finalTheme['shopsLocation'] = data.shopPoints.map((location: any) => {
            let finalLocations: any = {};
            finalLocations['shopPointId'] = location.shopPointId;
            finalLocations['shopPointName'] = location.shopPointName;
            finalLocations['shopPointLocation'] = location.shopPointLocation;
            finalLocations['shopPointPhone'] = location.shopPointPhone;
            return finalLocations;
          });

          finalTheme['missingImage'] = '/assets/static/missing-image.png';

          finalTheme['locations'] = data.shopPoints[0].shopPointLocation;
          finalTheme['amplifyConfig'] = theme.shopAmplifyConfig;
          finalTheme['amplifyConfigBackoffice'] = theme.shopAmplifyConfigBackoffice;
          finalTheme['shopLogoWhite'] = theme.shopLogoWhite;
          finalTheme['shopLogoLarge'] = theme.shopLogoLarge;
          finalTheme['shopLogoSmall'] = theme.shopLogoSmall;
          finalTheme['mainColor'] = theme.shopLogoMainColor;
          finalTheme['mainColorLight'] = theme.shopLogoMainColorLight;
          finalTheme['mainColorDark'] = theme.shopLogoMainColorDark;
          finalTheme['grayTitleActive'] = theme.shopLogoMainColorTitleActive;
          finalTheme['grayPlaceholder'] = theme.shopLogoMainColorTitlePlaceholder;
          finalTheme['fontFamily1'] = theme.shopFontFamily1;
          finalTheme['fontFamily2'] = theme.shopFontFamily2;
          finalTheme['shopIntegration'] = theme.shopIntegration != '' && theme.shopIntegration != undefined;


          // console.log(finalTheme);

          return finalTheme;
        });
      })
      .catch((error) => {
        console.log('Theming error');
        console.error(error);
      });
  }

  initialize(): Promise<any> {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.shopId = parseInt(<any>urlParams.get('shopId'));
    history.pushState(
      {},
      'null',
      window.location.pathname + `?shopId=${this.shopId}`
    );
    this.router.events.subscribe((val) => {
      history.pushState(
        {},
        'null',
        window.location.pathname + `?shopId=${this.shopId}`
      );
    });

    return new Promise<any>(async (resolve, reject) => {
      await this.getThemingService();
      resolve(0);
    });
  }
}

export interface NavbarCategory {
  name: string;
  categoryId: number;
  icon: string;
  iconActive: string;
}

export interface ShopTheming {
  shopId: number;
  shopName: string;
  currentContract: string;
  country: string;
  countryLanguage: string;
  countryTimeZone: string;
  countryCode: string;
  currencyCode: string;
  phone: string;
  messaging: string;
  messageImage: string;
  mail: string;
  shopLogoWhite: string;
  shopLogoLarge: string;
  shopLogoSmall: string;
  mainColor: string;
  mainColorLight: string;
  mainColorDark: string;
  grayTitleActive: string;
  grayPlaceholder: string;
  fontFamily1: string;
  fontFamily2: string;
  locations: string[];
  shopsLocation: string[];
  socialMedia: any[];
  enableSinpe: boolean;
  amplifyConfig: string;
  amplifyConfigBackoffice: string;
  shopIntegration: boolean;
  missingImage: string;
  version: string;
}
