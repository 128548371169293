import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';
import * as feather from 'feather-icons';
import parsePhoneNumber from 'libphonenumber-js';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  saleId: number = 0;
  loading: boolean = true;

  saleInfo: any = {};
  items: any[] = [];
  giftCards: any[] = [];

  saleStateName: any;

  initalSaleState: string = '0';
  currentSaleState: string = '0';
  saleStateChanged: boolean = false;
  loadingSaleStateChange: boolean = false;
  salePayment: any[] = [];
  beneficiaries: any[] = [];
  giftCardPurchase: any;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    public appComponent: AppComponent,
    public themingService: ThemingService
  ) {
    this.route.paramMap.subscribe((params) => {
      let saleId = Number(params.get('saleId'));
      if (saleId != this.saleId) {
        this.appComponent.goToTop();
        this.saleId = saleId;
      }
    });
  }

  ngOnInit(): void {
    this.saleStateName = {
      0: 'Cancelado',
      1: 'Pendiente de verificación',
      2: 'Preparando orden',
      3: 'Entregado',
      4: 'Pendiente Compra Click',
      5: 'Completado',
      6: 'Parcial'
    };
    this.getSale();
  }

  ngAfterViewInit() {
    feather.replace();
    this.formatDate
    this.getSale();
  }

  formatDate(date: string): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'dd-MM-yyyy HH:mm a') || '';
  }
  // formatDate(dateString: string): string {
  //    return this.appComponent.formatDate(new Date(Date.parse(dateString)));
  // }

  async getSale() {
    this.loading = true;
    await this.apiService
      .getSale(this.saleId, this.themingService.shopId)
      .then((data: any) => {
        this.loading = false;
        console.log("getSale",data);
        this.saleInfo = data.saleInfo;
        this.saleInfo.saleShortCode = this.saleInfo.saleOrderCode.substring(
          this.saleInfo.saleOrderCode.length - 5,
        );
        this.salePayment = data.salePayment,
        this.beneficiaries = data.beneficiaries,
        this.giftCardPurchase = data.giftCardPurchase,
        this.items = data.items;
        this.giftCards = data.giftCards || [];
        this.initalSaleState = this.saleInfo.saleState;
        this.currentSaleState = this.saleInfo.saleState;
        let shopPointName = this.items[0].shopPointName;
        for (let i = 1; i < this.items.length; i++) {
          if (shopPointName != this.items[i].shopPointName) {
            shopPointName = 'Mixto';
            break;
          }
        }
        this.saleInfo['shopPointName'] = shopPointName;
        if (
          this.saleInfo.latitude != undefined &&
          this.saleInfo.longitude != undefined
        ) {
          this.saleInfo[
            'wazeLink'
          ] = `https://waze.com/ul?ll=${this.saleInfo.latitude}%2C${this.saleInfo.longitude}&navigate=yes&zoom=17`;
        }
        this.saleInfo.saleDate = this.appComponent.formatDate(this.saleInfo.saleDate);
        let phone = <any>(
          parsePhoneNumber(
            this.saleInfo.clientPhone,
            <any>this.themingService.shopTheming[0].countryCode
          )
        );
        this.saleInfo.clientPhone = `+${phone.countryCallingCode} ${phone.nationalNumber}`;
          this.loading = false;
      })
      .catch((error) => console.error(error));
    }

    countBeneficiaries(itemId: number): number {
      return this.beneficiaries.filter(beneficiary => beneficiary.itemId === itemId).length;
    }

  getObjectKeys(object: any) {
    return Object.keys(object);
  }

  saleStateChange(event: any) {
    this.currentSaleState = event.target.value;
    this.saleStateChanged = true;
    feather.replace();
  }

  async changeSaleState() {
    this.saleStateChanged = false;
    this.loadingSaleStateChange = true;
    const selectedState = parseInt(this.currentSaleState);
    if (selectedState === 2) { 
        await this.apiService
            .changeSaleState(
                this.saleId,
                this.themingService.shopId,
                selectedState,
                this.saleInfo.saleOrderCode
            )
            .then(async () => {
                if (this.giftCards && this.giftCards.length > 0) {
                    this.sendGiftCardEmail(this.giftCards);
                }
            })
            .catch((error: any) => {
                console.error(error);
            });
        this.initalSaleState = this.currentSaleState;
    } else {
        await this.apiService
            .changeSaleState(
                this.saleId,
                this.themingService.shopId,
                selectedState,
                this.saleInfo.saleOrderCode
            )
            .catch((error: any) => {
                console.error(error);
            });
        this.initalSaleState = this.currentSaleState;
    }
    this.loadingSaleStateChange = false;
  }

  cancelSaleStateChange() {
    this.currentSaleState = this.initalSaleState;
    this.saleStateChanged = false;
  }

  copyWazeLink() {
    navigator.clipboard.writeText(this.saleInfo.wazeLink);
  }

  sendGiftCardEmail(giftCards: any[]) {
    this.loadingSaleStateChange = true;
    if (giftCards && giftCards.length > 0) {
        const shopId = this.themingService.shopId;
        const requestBody = {
            stage: this.apiService['stage'],
            shopId: shopId,
            giftCards: giftCards.map(giftCard => ({ giftCardId: giftCard.giftCardId }))
        };
        this.apiService
            .sendGiftCardEmail(requestBody)
            .then(() => {
            })
            .catch((error: any) => {
                console.error(error);
            })
            .finally(() => {
                this.loadingSaleStateChange = false;
            });
    } else {
        this.loadingSaleStateChange = false;
    }
}

}
