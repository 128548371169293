import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit {

  paymentTypes: any[] = [];
  paymentMethods: any[] = [];

  paymentMethodConfig: any = {};
  paymentMethodDescription: string = "";

  paymentTypeIdToCreate: number = 0;
  isActive = false;

  creditCardXPForm: any;
  creditCardForm: any;
  sinpeMovilForm: any;
  transferForm: any;
  payValidaForm : any;

  loading = false;

  constructor(
    private settingsService: SettingsService,
    private apiService:ApiService,
    public themingService: ThemingService,
  ) { }

  ngOnInit(): void {
    this.creditCardForm = new FormGroup({
      integration: new FormControl(''),
      gatewayKey: new FormControl(''),
      enviroment: new FormControl(''),
      user: new FormControl(''),
      password: new FormControl('')
    });
    this.sinpeMovilForm = new FormGroup({
      phone: new FormControl(''),
      description: new FormControl('')
    });
    this.transferForm = new FormGroup({
      iban: new FormControl(''),
      bac: new FormControl(''),
      bn: new FormControl(''),
      bcr: new FormControl(''),
      description: new FormControl('')
    });
    this.payValidaForm = new FormGroup({
      integration: new FormControl(''),
      merchantId: new FormControl(''),
      clientSecret: new FormControl(''),
      notificationSecret: new FormControl(''),
      countryCode: new FormControl(''),
      currencyCode: new FormControl('')
    })
    this.creditCardXPForm = new FormGroup({
      integration: new FormControl(''),
      enviroment: new FormControl(''),
      apikey: new FormControl(''),
      user: new FormControl(''),
      password: new FormControl('')
    });
    this.getPaymentTypes();
  }

  ngOnChange(){
    this.getPaymentTypes();
  }

  resetForms(){
    this.creditCardForm.reset();
    this.sinpeMovilForm.reset();
    this.transferForm.reset();
    this.payValidaForm.reset()
  }

  selectPaymentToCreate(event: Event){
    this.paymentMethodConfig = {};
    this.resetForms();
    const element = event.target as HTMLInputElement;
    this.paymentTypeIdToCreate = parseInt(element.value);
    console.log("Payment ID: ",this.paymentTypeIdToCreate);
  }

  async getPaymentTypes(){
    this.loading = true;
    await this.apiService.getPaymentMethods(this.themingService.shopId)
    .then((data: any) => {
      console.log("create pt data: ",data);
      this.paymentMethods = data.paymentMethods;
      this.paymentTypes = data.paymentTypes;

      this.paymentTypes.forEach((paymentType: any) => {
        const hasConfig = this.paymentMethods.some(config => config.paymentTypeId === paymentType.paymentTypeId);
        paymentType.hasConfig = hasConfig;
      });
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .finally(()=>{
      this.loading = false;
    })
  }

  validateFormData(){
    this.loading = true;
    console.log("submit form");
    // Pasalea de pago del BAC 
    if(this.paymentTypeIdToCreate == 1){
      const user = this.creditCardForm.get('user').value;
      const password = this.creditCardForm.get('password').value;
      const enviroment = this.creditCardForm.get('enviroment').value;
      if(user && password && enviroment){
        this.paymentMethodConfig.integration = "bac";
        this.paymentMethodConfig.gatewayKey = "";
        this.paymentMethodConfig.user = user.toString();
        this.paymentMethodConfig.password = password.toString();
        this.paymentMethodConfig.enviroment = enviroment.toString();
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
    }
    //Sinpe Movil CR
    else if(this.paymentTypeIdToCreate == 2){
      const phoneNumber = this.sinpeMovilForm.get('phone').value;
      const description = this.sinpeMovilForm.get('description').value;
      //this.paymentMethodConfig.phone = phoneNumber.toString();
      if(phoneNumber){
        this.paymentMethodConfig = {"phone":phoneNumber.toString()};
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
      this.paymentMethodDescription = description;
    }
    //Trnsferencia
    else if(this.paymentTypeIdToCreate == 3){
      const iban = this.transferForm.get('iban')?.value;
      const bac = this.transferForm.get('bac')?.value;
      const bn = this.transferForm.get('bn')?.value;
      const bcr = this.transferForm.get('bcr')?.value;
      const description = this.transferForm.get('description').value;
      if(iban){
        this.paymentMethodConfig.iban = iban.toString();
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
      if(bac){
        this.paymentMethodConfig.bac = bac.toString();
      }
      if(bn){
        this.paymentMethodConfig.bn = bn.toString();
      }
      if(bcr){
        this.paymentMethodConfig.bcr = bcr.toString();
      }
      this.paymentMethodDescription = description;
      
    }
    // Payvalida
    else if(this.paymentTypeIdToCreate == 5){
      const merchantId = this.payValidaForm.get('merchantId')?.value;
      const clientSecret = this.payValidaForm.get('clientSecret')?.value;
      const notificationSecret = this.payValidaForm.get('notificationSecret')?.value;
      const countryCode = this.payValidaForm.get('countryCode')?.value;
      const currencyCode = this.payValidaForm.get('currencyCode')?.value;
      if(merchantId && clientSecret && notificationSecret && countryCode && currencyCode){
        this.paymentMethodConfig.integration = "payvalida";
        this.paymentMethodConfig.merchantId = merchantId.toString();
        this.paymentMethodConfig.clientSecret = clientSecret.toString();
        this.paymentMethodConfig.notificationSecret = notificationSecret.toString();
        this.paymentMethodConfig.countryCode = countryCode.toString();
        this.paymentMethodConfig.currencyCode = currencyCode.toString();
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
    }
    // XPRESSPAGO
    else if(this.paymentTypeIdToCreate == 8){
      const user = this.creditCardXPForm.get('user').value;
      const password = this.creditCardXPForm.get('password').value;
      const apikey = this.creditCardXPForm.get('apikey').value;
      const enviroment = this.creditCardXPForm.get('enviroment').value;

      if(user && password && enviroment){
        this.paymentMethodConfig.integration = "xpresspago";
        this.paymentMethodConfig.APIKey = apikey.toString();
        this.paymentMethodConfig.user = user.toString();
        this.paymentMethodConfig.password = password.toString();
        this.paymentMethodConfig.enviroment = enviroment.toString();
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
    }
    this.createPaymentMethod()
    console.log("config to save: ", this.paymentMethodConfig);
    // this.loading = false;
  }
  
  createPaymentMethod(){
    this.loading = true;
    console.log(this.paymentMethodConfig);
    console.log(typeof(this.paymentMethodConfig));

    this.apiService.createPaymentMethod(
      this.themingService.shopId,
      this.paymentTypeIdToCreate,
      this.paymentMethodConfig,
      this.paymentMethodDescription,
      this.isActive?1:0
    ).then((data: any) => {
      if(data.status === 200){
        this.resetForms();
        $('#paymentMethodLoadingModal').modal('hide');
        $('#successPaymentMethodsModal').modal('show');
      }
      else{
        $('#errorPaymentMethodsModal').modal('show');
        $('#paymentMethodLoadingModal').modal('hide');
      }
      
    }) .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      this.resetForms();
      this.getPaymentTypes();
      this.paymentTypeIdToCreate = 0;
      this.loading = false;
    });
  }
  
  isConfigured(paymentTypeId: number){
    this.paymentMethods.forEach(paymentMethod => {
      console.log(paymentMethod);
      console.log(paymentTypeId);
    });
  }
  
  closeModal(){
    $('#successPaymentMethodsModal').modal('hide');
    $('#errorPaymentMethodsModal').modal('hide');
    $('#requiredPaymentMethodModal').modal('hide');
  }
  
  setListingPaymentMethods(){
    this.settingsService.setListingPaymentMethods();
  }

}
